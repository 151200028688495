import React, { useEffect, useState, useRef } from "react"
import classnames from "classnames"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"
import PullQuote from "../PullQuote"
import AnimatedNumber from "../../common/AnimatedNumber"
import Carousel from "../../common/Carousel/Carousel"

// carousel image assets
// import CarouselImage1 from "../../../images/people/carousel_image_1.webp"
import CarouselImage1 from "../../../images/people/carousel_image_1_lena.webp"
import CarouselImage2 from "../../../images/people/carousel_image_2_new.webp"
import CarouselImage3 from "../../../images/people/carousel_image_3_chris.webp"

// import CarouselImage1Fallback from "../../../images/people/carousel_image_1.jpg"
import CarouselImage1Fallback from "../../../images/people/carousel_image_1_lena.jpg"
import CarouselImage2Fallback from "../../../images/people/carousel_image_2_new.jpg"
import CarouselImage3Fallback from "../../../images/people/carousel_image_3_chris.jpg"

import OpenRoles from "./OpenRoles"
import "./WorkingAtMediaLink.scss"

const WorkingAtMediaLink = ({ jobListings, scroller }) => {
  const [bigSizing, setBigSizing] = useState({})
  const bigNumber = [
    {
      number: "200+",
      caption:
        "renowned executives, unparalleled subject-matter experts and innovative entrepreneurs transforming business across all areas of the digital ecosystem",
    },
  ]

  const dogNumber = [
    {
      number: "24",
      caption: "four-legged assistants waiting for us at home",
    },
  ]

  const locationNumber = [
    {
      number: "4",
      caption: "locations spanning across North America and Europe",
    },
  ]

  const carouselImages =
    isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
      ? [CarouselImage1Fallback, CarouselImage2Fallback, CarouselImage3Fallback]
      : [CarouselImage1, CarouselImage2, CarouselImage3]
  const viewSize = useRef(null)

  const determineBigSizing = () => {
    const newSize =
      window.innerWidth < 1024 ||
      (window.innerWidth < 1100 && window.innerWidth < window.innerHeight)
        ? "mobile"
        : "non-mobile"
    if (newSize !== viewSize.current) {
      viewSize.current = newSize
      setBigSizing(
        newSize === "mobile"
          ? { type: "left", maxWidth: "100%" }
          : { type: "dynamic", maxWidth: "100%" }
      )
    }
  }

  useEffect(() => {
    determineBigSizing()
    window.addEventListener("resize", setBigSizing, { passive: true })
    return () => {
      window.removeEventListener("resize", setBigSizing, { passive: true })
    }
  })

  return (
    <div id="people-working-at" className={classnames("working-at", {})}>
      <h2>Working At MediaLink</h2>
      <h3>Where the best of the best come to work.</h3>
      <Carousel spaceBetween={20}>
        {carouselImages.map((image, i) => (
          <div key={image} id={(image, i)} className="carousel-image">
            <img src={image} alt="carousel placeholder" />
          </div>
        ))}
      </Carousel>
      <div className="working-at__below-carousel-text">
        <div className="text-column">
          We&apos;re not merely strategists. Or consultants. We&apos;re
          diversely-experienced experts focused on evolving our clients&apos;
          businesses through action and implementation. We seek individuals with
          impressive track records from a variety of backgrounds who can
          continue to enrich the DNA of our company.
        </div>
        <div className="text-column">
          Whether you&apos;re a seasoned executive interested in leveraging your
          operating experience and unique expertise, or looking to begin a
          career at the intersection of industries, MediaLink provides a dynamic
          and endlessly stimulating work environment.
        </div>
      </div>

      <PullQuote>
        Where unmatched talent meets unparalleled experience.
      </PullQuote>
      <div className="working-at__numbers">
        <AnimatedNumber
          id="wamlbig"
          numbers={bigNumber}
          displayWhenVisible={false}
          size="big"
          sizing={bigSizing}
          scroller={scroller}
        />
        <div className="working-at__numbers-row">
          <div className="working-at__numbers-column">
            <AnimatedNumber
              id="wamldog"
              numbers={dogNumber}
              displayWhenVisible={{ offset: 5 }}
              size="small"
              sizing={{ type: "left", maxWidth: "100%" }}
              scroller={scroller}
            />
          </div>
          <div className="working-at__numbers-column">
            <AnimatedNumber
              id="wamllocation"
              numbers={locationNumber}
              displayWhenVisible={{ offset: 10 }}
              size="small"
              sizing={{ type: "left", maxWidth: "100%" }}
              scroller={scroller}
            />
          </div>
        </div>
      </div>
      <div className="working-at__open-roles-intro">
        <h3>A network unlike any other.</h3>
      </div>
      <OpenRoles jobListings={jobListings} />
    </div>
  )
}

export default WorkingAtMediaLink
